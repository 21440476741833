import React, { Component } from 'react';

class PagePhotography extends Component {
    render() {
        return (
            <div className="Page-Container">
                <div className="Page">
                <h2 className="Page-Title">Photography</h2>
                </div>
                
            </div>
        )
    }
};

export default PagePhotography