import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PageHome from '../Pages/PageHome'
import PageAbout from '../Pages/PageAbout'
import PagePhotography from '../Pages/PagePhotography'
import PageProjects from '../Pages/PageProjects'
import PagePapers from '../Pages/PagePapers'
import PagePapersUbiTO from '../Pages/PagePapersUbiTO'
import PagePapersTest from '../Pages/PagePaperTest'

function Container({ location }) {
    return (
        <Switch location={location}>
            <Route exact path="/" component={() => <PageHome />} />
            <Route path="/About" component={() => <PageAbout/>} />
            <Route path="/Photography" component={() => <PagePhotography/>} />
            <Route path="/Projects" component={()=> <PageProjects/>} />
            <Route path="/Papers/My-Story-Ubisoft-Toronto" component={() => <PagePapersUbiTO/>} />
            <Route path="/Papers" component={() => <PagePapers/>} />
            
        </Switch>
    )
};

export default Container;