import React, { Component } from 'react';

import Headshot from '../Images/chel_headshot.JPG'

class PageHome extends Component {
    render() {
        return (
            <div className="Page">
                <div className="Image-Container">
                    <img className="Home-Headshot" src={Headshot} />
                </div>
            </div>
        )
    }
};

export default PageHome