import React, { Component } from 'react';

class PageAbout extends Component {
    render() {
        return (
            <div className="Page">
                <h2 className="Page-Title">About</h2>
                <p className="Page-Para">My name is Chelsea, my friends call me Chel. I'm still kicking it in Toronto, Ontario.
                I really dig all things techy, plants, animals, and music. I take pictures, too. <br/><br/>
                This website serves as an exhibit of me. As I figure out who I am, I'll share it on this little node of the internet.</p>
            </div>
        )
    }
};

export default PageAbout