import React, { Component } from 'react';
import { NavLink, BrowserRouter } from 'react-router-dom';

class Header extends Component {
    render (
        // stuff
    ) {
        return (
            <div className="header-bar">
                <div className="navigation">
                    <div className="nav-menu">
                        <div className="home-button"><a href="/"><img src={require('../Images/dragonfly.png')}/></a></div>
                        <ul className="nav-items">
                            <li className="nav-link">
                                <NavLink className="link" activeClassName="active-link" to="/About">About</NavLink>
                            </li>
                            {/* <li className="nav-link">
                                <NavLink className="link" activeClassName="active-link" to="/Photography">Photography</NavLink>
                            </li> */}
                            {/* <li className="nav-link">
                                <NavLink className="link" activeClassName="active-link" to="/Projects">Projects</NavLink>
                            </li> */}
                            <li className="nav-link">
                                <NavLink className="link" activeClassName="active-link" to="/Papers">Papers</NavLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        )
    }
};

export default Header