import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

class PagePapers extends Component {
    render() {
        return (
            <div className="Page-Container">
                <div className="Page">
                <h2 className="Page-Title">Papers</h2>
                    <div className="Papers-Menu">
                        <NavLink to="/Papers/My-Story-Ubisoft-Toronto" className="Papers-Menu-Item">20200702_Ubisoft_Toronto</NavLink>
                    </div>
                    </div>
                
            </div>
        )
    }
};

export default PagePapers