import React, { Component } from 'react';

import Tabs from '../Components/Tabs';

import ProjectVerticalGarden from './Projects/ProjectVerticalGarden'

class PageProjects extends Component {
    render() {
        return (
            <div className="Page-Container">
                <div className="Page">
                <h2 className="Page-Title">Projects</h2>
                
                </div>
                <div className="Tab-Container">
                    <Tabs>
                        <div label="Vertical Garden">
                       
                            <ProjectVerticalGarden/>
                        </div>
                        <div label="Project-02">
                            Some other project stuff.
                        </div>
                    </Tabs>
                </div>
            </div>
        )
    }
};

export default PageProjects