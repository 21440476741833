import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import emailSignature from '../Images/chelohara_email_signature.jpg'
import letterAlex from '../Images/chelohara_lettertoalex.png'
import videoUbi from '../Images/ubisoft_toronto_mentorship_2015.mp4'

class PagePapersUbiTO extends Component {
    render() {
        return (
            <div className="Page-Container">
                <div className="Page">
                <h2 className="Page-Title">Papers</h2>
                <NavLink className="back-button" to="/Papers">← Back</NavLink>
                <div className="Paper-Container">
                <h3 className="Paper-Title">Breaking My Silence on Ubisoft Toronto</h3>
                <div className="Paper-Details">Published 2020/07/02</div>
                <div className="Paper-Body">
                    <p className="Para-Indent">This has been written over the course of several days in response to the <a target="blank" href="https://twitter.com/DennyVonDoom/status/1276648649565638657?s=20">allegations that continue to surface</a>. I am emboldended by the Women of Ubisoft, past and present, who are courageous enough to speak up and stand up for themselves and others. This is for those who can, and those who cannot, share their stories - may we stand stronger together.</p>
                    <div className="Paper-Break"/>
                    <p>I do not have words to describe all the feelings I have right now. </p>
                    <p>I have been spontaneously breaking down into tears while reopening each wound and taking a look once more at these past mental traumas.</p>
                    <p>I am angry. I have been angry for a long time that Ubisoft continues to keep <a target="blank" href="https://toronto.ubisoft.com/2015/08/announcing-the-2015-recipient-of-ubisoft-torontos-producer-mentorship/">records on its website of my past self</a>. The loving, excitable, passionate, warm, driven girl. She died, at some point in all this. Suffocated in a corner by closeted sexism. She is gone forever.</p>
                    <p>I am numb. Reading the stories I have heard behind closed doors, recollected in dive bars over too many beers, whispered in meeting rooms when we felt we were alone, now out on the internet for all to search and see. These stories are real, they happened to real women, with real aspirations, dreams, talents, and experiences. The pain is glossed over, the facts are restated over and over until everyone has them permanently etched into their minds.</p>
                    <p>I am furious. The men who stunted my career growth, broke me down, and scarred me permanently, are still in their positions over two years later. Nothing has changed. The letter I wrote to the Managing Director of Ubisoft Toronto upon my resignation - urging him to take a hard look at my resignation and to use the facts to drive change - unsurprisingly did nothing.</p>
                    <p>I am disgusted. Recounting the last formal meeting with my manager, where I broke down in tears, explaining that after a year and a half of working 60 - 80 hours a week, I could not do it anymore for the same pay and title. I either needed to be given the same treatment and respect as the other managers on our team, or be shown how I can be mentored into a more technical position. If there is no change, I will have no choice but to leave. And his sarcastic remark “Well if you think that’s the best thing for your career, you should go.”  I made the right choice in resigning. I am now a software developer.</p>
                    <p>I am broken. I flipped through my time there with an impartial mind’s eye, brought back by reading the words “systemic sexism”, “sexual harassment”,  “drunken violence”, “bullied women”, “toxic culture”. I keep thinking, <i>wow I am lucky I was never physically assaulted, I am lucky I was never physically assaulted, I should not downplay my own experiences but I am lucky I was never physically assaulted</i> -- and then, I turn the corner and happen upon the memory I buried so deep down I didn’t tell anyone. I sob as I remember I was groped in the back of a taxi while being escorted home by a male co-worker. I was too drunk to do anything but lie there, but I remember it. I despised the way he would come up behind me at work and sniff my hair, telling me it smelled amazing. I despised the crude and sexist jokes he would make. I remember it, but I didn’t tell anyone.</p>
                    <p>I am fed up. I am drained. I have no more drive or energy to give to the gaming industry. But it will not defeat me. For I have grown and changed and healed in spite of this continuing memory.</p>
                    <p>And so I said, “I will share my story”. Seeing the other women come forward, I too want to add my voice to their chorus. Even if I am pessimistic about the possibility of any reform, reparation, or reconciliation.</p>
                    <p>I was used as a poster-child, a success story, of a woman ‘breaking in’ to Ubisoft Toronto. Now I will collect that debt owed to me.</p>
                    <div className="Paper-Break"/>
                    <p>My story is a long one, but I will tell it all.</p>
                    <p>One evening when I was about 14 or 15, my mom showed me a page from the day’s newspaper that she had saved. It was an article on Jade Raymond, one of the producers on the Assassin’s Creed game. She knew I loved video games and she was excited to show me that women could make successful careers in this growing industry. I was hooked - like many others, Jade Raymond became one of the coolest people in my young mind.</p>
                    <p>I followed her career as I tried to figure out my own. At the end of a Film and Media Degree, I had my eyes set on the Ubisoft Toronto studio, where Jade was the Managing Director. To think a woman was leading a whole AAA studio, and in the city I was raised! I had to be there. I had no other path but to get in.</p>
                    <p>Through a series of networking and happenstance encounters, I learned of the Ubisoft Toronto Producer Mentorship, a program supported by WIFT-T and hosted at the Ubisoft Toronto Studio. “This immersive one-week mentorship … provides one female producer with a unique behind-the-scenes learning experience in all aspects of game production onsite at the Ubisoft Toronto Studio.” I applied right out of school, and after being interviewed by Jade Raymond (!!!!!!!!) I was promptly rejected. But I wouldn’t give up.</p>
                    <p>I spent a year bettering every aspect of myself. I was a go-getter, and I wanted a foot-in-the-door at the only AAA studio in Ontario. I took on projects in web development and volunteered at industry events. I went to conferences, sought out mentors, and improved my understanding of what I would need to make the cut. I applied again in 2015, and was accepted.</p>
                    <p className="Para-Indent">As the recipient of the Ubisoft Toronto Producer Mentorship, O’Hara will experience everything from a performance capture shoot to an inside look at the processes involved in creating AAA quality games. The mentorship will provide O’Hara with an opportunity to experience the latest gaming innovation, make exceptional contacts at a leading video game studio, and understand how to cross pollinate ideas from one screen to the next.</p>
                    <p className="Para-Indent">“Being selected for this mentorship honestly feels like a dream come true,” said Chelsea O’Hara, the 2015 Ubisoft Toronto Producer Mentorship recipient. “Twelve-year-old me is freaking out with excitement right now. Thank you friends and family for your ongoing support, and special thanks to Ubisoft Toronto and WIFT-T for making this opportunity possible!”</p>
                    <video width="100%" height="315px" controls>
                        <source src={videoUbi} type="video/mp4"/>
                    </video>
                    <p>It was an absolute dream come true. The whole week played out like a fairy tale. I met some of the “biggest” names at the studio in one-on-one interviews. By that point, Jade Raymond had left, but there were so many other incredible women that truly inspired me to find my place there. I remember being told that “over 40% of the senior level management are women”. I was ecstatic.</p>
                    <p>(I was also interviewed for <a target="blank" href="https://www.huffingtonpost.ca/2015/12/06/gamergate-women-video-games-gender-gaming_n_8722640.html">this HuffPost article</a>, which is infuriating and heartbreaking to re-read now. The comment section, though, is a pretty strong indication of why nothing has changed.)</p>
                    <p>At some point during my week there, I realized that there was no separation of technical and art for me. If I wanted to truly understand and lead on game production, I needed programming skills. This, I will clarify, is entirely my opinion. I inquired when possible about the path to programming games at Ubisoft Toronto.</p>
                    <p>“We only accept computer engineers, from the University of Toronto or the University of Waterloo.” One of the HR reps was adamant that programmers from any other school or degree program were not up to par. “Either that, or ship a AAA game.” </p>
                    <p>At the end of the week, I was sent on my way with promises of job opportunities and follow-up emails. The beginning of 2016 came, and I saw an opening in the department of my choice - Motion Capture Coordinator. I immediately applied, and waited. I followed-up, and waited. I was told, eventually, that it had been filled. I was rejected because they were looking for someone with more production experience.</p>
                    <p>Around this time, I had started to shift my perspective of my career path. If I really needed an engineering degree, maybe I should get one. And if I get an engineering degree… by the time I am done, maybe Ubisoft won’t be the place I apply.</p>
                    <p>To go back to school for an engineering degree, I would need prerequisites. High school prerequisites. That, or return to Queen’s University and complete a year of first year science and math courses, which I could not afford. But nothing would stop me -  so I applied and went back to high school at night.</p>
                    <p>Four nights a week (Monday - Thursday) I biked frantically around Toronto from my job to two different campuses. To start, I took grade 11 physics and grade 12 chemistry. I absolutely fell in love with learning science in a way I had never felt before, especially with physics. I was ready to face my fears and tackle the terrifying maths I never did in high school, all in pursuit of a career in games.</p>
                    <p>The summer rolled in and I was half way into a grade 12 Advanced Functions course, when the call came from Ubisoft. “We have an open position in the Motion Capture Department - are you interested?”</p>
                    <p>Fuck yeah I am.</p>
                    <p>At my first round of in-person interviews, I sat down with my to-be direct manager, who I will refer to as Keith. At one point, I asked the question that was truly tormenting me - what would I gain here that would be better than the possibility of an engineering degree, which I was actively working towards? He was quick to lay on the reassurances, that the R&D team in the Motion Capture Department was building the coolest, latest technologies, that the guys were so smart and that I would have an opportunity to learn from them. I ate it up. I want to be a Motion Capture Technician! I want to work in hardware and software for games! Hire me! Hire me!</p>
                    <p>In September 2016, I was offered the job of Motion Capture Coordinator.  I was ecstatic all over again! The engineering degree could wait, or be postponed forever, because I was going to be working in games and I would work my way into any position. I truly believed nothing could stop me.</p>
                    <p>After a few weeks in my new role, I had a standard scheduled interview with my HR team lead. He was very bubbly, the kind of charm that makes you need to fizzle over in response. He asked me a list of prepared questions, stuff about my experience so far and where I want to go within the company. When I told him I wanted to be transitioned into a Motion Capture Technician position at the right time, he flamboyantly declared, “Wow! I would have never pegged you for a hardware girl.” I laughed.</p>
                    <p>A hardware girl.</p>
                    <p>I could not process in the moment how weird that was. But it was so strange that it immediately burned into my mind, and I didn’t know why. That was the first red flag. But either I didn’t know it, or I was too high on my honeymoon at Ubisoft Toronto to dwell on it, so I carried on happily thinking I was the cat’s pajamas.</p>
                    <p>In January 2017, everything changed. Another coordinator in the department, I’ll refer to her as X, had a genuine nuclear blowout with Keith. A screaming match, with the studio's Managing Director witnessing it. The details are probably deemed confidential under a number of NDAs, but the reality is that X was taking the fall, yet again, after five years of taking the fall, and she was not falling today. </p>
                    <p>It was so bad, they gave her an indefinite sabbatical.</p>
                    <p>That’s when the other women started to talk. I was pulled into meeting rooms and given the ‘low down’. I was brought off-site for lunch or dinner to ‘talk’.  Now the wool was pulled back from my eyes, and I was able to see what was really going on. It wasn’t just X, it was Y and Z as well. It was A, it was B, it was F, it was G, it was the whole damn alphabet and more. If you were a woman at the studio, you had a story. You likely had a stunted career, and a story. Or two. Or ten.</p>
                    <p>Despite the now growing red flags, I was still determined to advance my career. I had this uncontrollable obsession with advancing into a technical role. Every moment I could, I was on the stage, hanging out in the green room asking questions, staying after hours to do odd jobs and projects. Oh, and playing Rocket League.</p>
                    <p>Why playing Rocket League?</p>
                    <p>Well, in my interview with Keith, it came up that Rocket League was the lunch-time game of choice for all the ‘downstairs’ team members (aka those that worked on the motion capture floor doing shoots). So before I even got hired, I immediately spent many, many hours getting good at Rocket League. I wanted to fit in, and show I was a gamer too.</p>
                    <p>So, I spent a lot of time in what I very quickly realized was the boys-club of the department. And as I am, I was ever-determined to make my place and be a part of it. Hence, Rocket League.</p>
                    <p>But there wasn’t just Rocket League, there was alcohol. And lots of it. There were four major parties in my first few months there, including the Watch Dogs 2 wrap party which I was generously invited to. It was wild - I came from a job in the finance sector and I never saw people drink like this with co-workers. Admittedly, I too became dependent on alcohol to cope with the increasingly fucked up shit I was dealing with.</p>
                    <p>It started with small things. Like how Keith realized my forecasting was really good, so he wanted me to prepare it for him to pass off as his own in a meeting with Alex. Fine by me, except after I sent it to him, he would turn to me and say - “Oh can you remove the pink?”</p>
                    <p>The pink he is referring to was an official Ubisoft colour from their palette, which I had downloaded from their internal sites. My past life as a Digital Media Specialist made me adhere to the code of a strong brand standard. I tried to explain that it was not really a pink but also, it’s an official colour, so is it really a big deal?</p>
                    <p>Yes. No pink. Too girly.</p>
                    <p>I removed it, but simultaneously became annoyed. Why is this guy so hung up on pointlessly gendered ideas about colour? Every Excel table I sent to him after that had some pink in it. I would watch him change the colours on his computer before he sent the reporting to his superior. I decided it was high time I changed my email signature.</p>
                    <img className="Paper-Image" src={emailSignature} />
                    <p>And my first performance review. Keith praised me up and down for the work I was doing, not a negative thing to say - except for one thing. “You took too long on those reconciliations.” We had to do a few months of correcting payments, and it was a tedious and annoying re-calculation of dozens of contracts. I offered to take it on, and my approach was to attempt a script in VBA that would calculate the new actuals based on our old files, and export a branded PDF with the payment adjustment details to go out via the Finance department. I figured I could learn some automation techniques and get more comfortable thinking about how to solve tasks with code.</p>
                    <p>It worked, but the upfront time investment to automate it did take me - a junior at programming and completely new to VBA - longer than predicted. Keith implied it was a waste of time and that I had gained nothing. Personally, I strongly disagreed with his opinion on this, but at the time I nodded and agreed. No more programming for me, do paperwork manually from now on.</p>
                    <p>There were a handful of us on the team that did similar work - a lot of paper pushing and reporting, coordinating resources and adjusting budgets multiple times a day as creatives changed their minds. The only man, I'll call him Alvin, was evidently Keith’s wingman. He got away with scraping by on the bare minimum amount of work, usually amounting to another manager - a woman - recalculating all of his numbers and triple checking all of his info. He succeeded because she was extremely neurotic about things being correct.</p>
                    <p>When I started, Alvin and I had similar titles of ‘Coordinator’. Of course, we had drastically different responsibilities. He commented multiple times on how amazing it is that I am able to get so much done. “I can’t do what you do,” he would say. Alvin was promoted shortly after I started to ‘Manager’. I was still gung-ho on becoming a Technician, but over time it was undeniable that, if Alvin was a ‘Manager’, I was also doing the work of a ‘Manager’.</p>
                    <p>It is important to note too how different working on the Motion Capture Team was to working on a single game production. At Ubisoft, game developers are assigned to a single project with a codename. To be ‘on production’ meant that your work load varied with the eb and flow of the AAA development cycle of a single title. There were sprints, and large milestones to work up to through sprints. Crunch periods occurred for the weeks leading up to a large milestone. But there tended to be some down time, periods where things would relax a bit and the standard 9 - 5 hours could be hit.</p>
                    <p>With Motion Capture, the department was run as a service to not only all the Ubisoft Toronto productions, but any productions worldwide that wanted to use our motion capture stage. That meant calls with LA, Vancouver, Montreal, Quebec City, London, Paris, Shanghai, Singapore, etc. It meant looking at resources and talent across different time zones, dealing with international work permits and contracts, and rejigging hardware/software pipelines for different studio production setups. Most importantly, we worked on their schedules. There was always a crunch, and we were always supporting someone’s last minute script rewrite or trying to get something recorded to get into the game version before a big milestone. There was no down time, and I was easily working on average 70+ hours a week.</p>
                    <p>I would get up at 4am to get on calls with partner studios in London. I would be up at midnight completing contracts that needed to be ready at a recording studio the next day. I had my email on my phone and was constantly reading and replying. My laptop came with me everywhere - hangouts with friends, family dinners. My female co-workers kept wine at their desks and would open a bottle at 4pm, because they knew they would be there until 7 or 8 or 9 pushing paper. There was no down time for us. I would go to lunch to get a drink so I could relax enough to get through the ever-increasing stress of keeping on top of everything. Alcohol was a coping mechanism; we were overworked and underpaid, our relationships outside of work strained, and the closeted sexism loomed and normalized everything we experienced.</p>
                    <p>It became apparent I was doing the job of two or three people. I took time to put together detailed forecasts of my own work, and presented them to Keith. I need help. Please hire another coordinator to share my workload. I started looking into my network to see if there was anyone I would recommend. Eventually, Keith pulled me into a meeting room and said that a woman from another team - I’ll refer to her as Violet - was to join our team. He was not happy about it, because she had been moved between three different departments already and was clearly, in his words, being dumped on us. I remember being positive, saying that we could make it work. I would give her very small chunks of my work so as to not overwhelm her. I wanted her to succeed.</p>
                    <p>For a few weeks, it worked really well. I prepared detailed instructions on how to do things, and provided an effective pipeline for her to learn. I had checklists like you couldn’t imagine. But it helped her to stay on top of things, and it was helping to ease my workload.</p>
                    <p>Then, Violet wanted to do more. She was older, she had more experience in this stuff, she would tell me over and over how qualified she was. My own experience of her when she was in production contradicted this, and I was hesitant. We were coming up to an important milestone on an internal game, very high stakes that I took too personally. But she was adamant, and she was a ‘Manager’, and of course I could use help. Keith pushed for it.</p>
                    <p>I brought her in one what was going on and I explained everything that was happening. A few days in, she could not keep up. She got so overwhelmed so quickly, and wrote a reactionary email to Keith and myself, asking me to back off and slow down. I was with Keith at the time, and I immediately asked him for help dealing with this situation. There is too much technology she is unfamiliar with, I have been working on this for weeks and know it in and out, and we are on a risky timeline - can you please get her to leave it to me? She’s new to the team, can she stick to what she’s been doing well? Of course, he said. “Don’t worry. I’ll deal with it.”</p>
                    <p>I felt he was on ‘my side’. And other managers/coordinators in the department were also aware of the huge stress it was having her on the team. We were all trying to deal with an unpleasant situation and make the best of it. And since she was partnered with me, I needed to be positive and professional about it.</p>
                    <p>But tensions continued to increase between myself and Violet. In my mind, she was dragging not just me but the whole department down. People gossiped to me about how bad she was at her job. She made mistakes I was tasked with cleaning up. It compounded into an evening - right before the milestone and at the height of my anxiety - where she cornered me, wanting to talk. I was not having it, I was so at my wits end. I was short, and rude, and matter-of-fact -- she rolled her eyes at me and I said, "Don’t roll your eyes at me." She was shocked at my tone, she said shakily that she’s walking away, and I turned back to my computer and continued my long list of tasks. I was so, so angry.</p>
                    <p>Once our milestone was hit a day or two later, I calmly went to my HR team lead.</p>
                    <p>I want to pause and make it clear that it takes <i>a lot</i> for me to make a formal complaint about a co-worker about their work. People who have worked with me will know that I live by a philosophy of engaging and elevating others around me. It was no different with Violet. I had tried my best to support her succeeding in our department, and she took every opportunity to use her title to tear me down and impose superiority, not just over me but others on our team. I was not the only one who had made it clear to management that she needed to take a ‘back seat’.</p>
                    <p>So, my HR team lead and I sat down, and I began my talking points. I said she is not a good fit for the team and I had a prepared list of reasons why. And then, without warning, he completely lost it on me. Violet had already talked to him, and he knows that I ‘yelled at her’. He was so unbelievably snappy and offensive that I was completely taken aback. Nothing I argued would land. He was very clearly on her side without knowing anything of what I was going through. He gestured with his hands “She’s up here, you’re down here”. I was floored. “We only hired you because you are passionate.”</p>
                    <p>I tried to start from the beginning. “There was this very important recording we needed done in L.A., one shot, $75,0000….” He interrupts me. “Oh yes I know, this <i>massive</i> shoot, this <i>massive</i> recording that’s <i>sooo</i> important.”</p>
                    <p>I’m not one to be sarcastically torn down about things I know are important and serious. I paused, then said “Well, technically to call it massive, it needs to have some sort of mass, which it doesn’t, since it is an event and as such just an idea with no physical mass.”</p>
                    <p>He looked at me like he was going to lunge over the table and hit me. His face got red and  his eyes widened. He slammed his notebook closed and shot up from his chair, storming towards the door. “Well, if this is how you are I am not talking to you anymore!” He yelled.</p>
                    <p>My jaw dropped. “It’s a joke, it’s a joke!” I tried to reassure him. “Please sit down. Okay? I am joking, it’s a nerdy joke, that’s how I deal with stress.”</p>
                    <p>He righteously sat back down, and as I continued to talk, it was evident he didn’t give a flying fuck about me. It was over. In his mind, I was the problem.</p>
                    <p>I left the meeting and called a close confidant, the woman who had my job before me. I explained what happened with my HR team lead. “Oh sweet summer child,” she cooed. “You cannot complain to HR about another employee.”</p>
                    <p>Violet had effectively ‘won’. After a meeting with HR, Keith turned completely against me. I could not explain myself, could not defend my work. No matter how many people came to my side and sang my accomplishments to him, it was very clear to the whole department that Keith had no time for me and he was siding with Violet. </p>
                    <p>Looking back, I see now that we were pitted against each other. The women were treated as ‘uncontrollably emotional’, ‘unable to logic’, ‘needing to be put in place’, ‘the reason for gossip and drama’.</p>
                    <p>The months went on, and tension grew between Keith and myself. At the same time, the equivalent Ubisoft Montreal Studio department - ALICE - wanted to build stronger relationships with our department. Their audio team came to visit. Violet and I were forced to work together, and we did. We were civil. We were effective. For the most part in our day-to-day, we worked on different things, but on these shared tasks, we were okay.</p>
                    <p>At some point during this time, I went to Keith with a list of things I wanted him to give to Alex  Parizeau about one of the game projects. This came about after a conversation I had with one of the lead programmers on the project. Him and I had first met during the mentorship, and he was curious about how I was doing. I told him I had some grievances, and specifically, how our team works with the project team. He urged me to talk to Alex. “Alex needs to hear this, I know him well and I know he would listen.”</p>
                    <p>I also knew this to be true. When I did the mentorship and sat with him one-on-one, Alex had told me that he will listen to any person who comes into his office with feedback, because anyone “brave enough to speak up should be listened to”. But, out of respect to Keith, I tried to adhere to the hierarchical structure of the company: I reported into Keith, Keith reported into Alex, Keith can pass this on to Alex. So I brought him my list of suggestions for how we can be effective as a department working with the production, since it was, quite frankly, a shitshow.</p>
                    <p>It backfired. Keith smiled and made a small laugh while refuting everything that I said. He was seemingly so annoyed that I thought this was important. He shook his head. He did not want the status quo to be upended. He would not present this to Alex.</p>
                    <p>A few months later, I was given an insultingly negative annual performance review by Keith. None of my contributions or accomplishments were talked about. I argued that I did as much or more work on the team than some of the Managers, and I deserved to be promoted for my efforts. But there was no way Keith was going to allow me to move up. He treated me like I was delusional.</p>
                    <p>Shortly after, in March 2018, I was called into mediation with Violet. Why? Apparently, she was blaming her stress at work on my lack of acknowledgement of her. Where or how that came up, I still do not know. I had the feeling Keith was making this an issue, and I was so at a loss for words. I treated her with respect, and I was always professional. It seemed to me that she was projecting her insecurities about the way she fit into the department on me, and Keith was using it to deny me the promotion I had asked for in my recent performance review. This was a ‘formal intervention’ to document I was problematic.</p>
                    <p>The three of us in a meeting room, and I am livid. After a conversation that goes nowhere, since I am firm in my position that work is getting done, discussions are aimable and constructive, “What’s the problem exactly?”, Keith gets Violet to leave. Keith and I barely talk one-on-one anymore, and now I am crying and upset. He tries to throw it on me that he can’t help me with the way I am acting. I was being gaslit into thinking I was terrible.</p>
                    <p>But I know my worth, and I stand up for myself. I tell him through tears that I cannot keep doing this - either promote me and pay me fairly for the work that I do so that I can at the very least be known to the have the same title as my peers, like Violet and Alvin, or show me how in the coming months or year, I can be transitioned to be a Technician. </p>
                    <p>“No.” He shook his head vigorously. “No to either of those things.”</p>
                    <p>He wanted to keep me exactly where I was - on coordinator pay, entry-level, doing the job of two managers. Managing multi-million dollar budgets, responsible for the timely execution of a half-dozen game audio recordings, for measly income and no bonus. It was where the female coordinators before me had been for over five years, until burning out or moving out. It was so clear that there was no growth for women in the department, because to Keith, they were inferior to men.</p>
                    <p>“Then I have no choice but to leave.” I said firmly.</p>
                    <p>He threatened, “If you think that’s the best thing for your career, you should go.”</p>
                    <p>I shook my head, tears coming down my face. He left the room. One week later, I had another job offer for a project manager position at an internet marketing company. I took it.</p>
                    <p>My co-workers were floored at the news. “What are we going to do? We are going to be fucked without you.” We scrambled to prepare some transitions.</p>
                    <p>Meanwhile, my HR team lead was trying to get me to leave immediately with pay. I hotly replied that there was much to be done, and that I wouldn’t leave my team hanging. He cared more about getting rid of me than about how the team would be able to cope. I was firm that I would leave at the stated last date.</p>
                    <p>I took this time to write a very detailed letter to Alex. It was my last shot to drive change at the studio for the people who were working hard to make it better.</p>
                    <p>It is so surreal to re-read this letter now, knowing that over two years later, with all the stories crashing out the doors like tsunami waves, nothing was done. I have included the end of the letter below.</p>
                    <img src={letterAlex} className="Paper-Image"/>
                    <p>Leaving felt like breaking away from a cult. I had this overwhelming feeling of dread, like I had royally fucked up. At my new job, I would take breaks frequently in an attempt to curb anxiety attacks, but would end up walking down the street and hyperventilating instead. I broke down often. I continued to drink excessively.</p>
                    <p>But, slowly I saw my self worth again. At my new workplace, I had an absolutely incredible manager - and friend - who helped me succeed in my new role. I was praised for my work, and my ability to collaborate effectively with their overseas team in India. While at Ubisoft, I had been scraping by in my high school courses. Now, I focused on completing them with better grades. I finished with a 94 in grade 12 physics.</p>
                    <p>In the summer of 2018 I was offered a software developer job at a solar technology company. I worked there until I was let go recently due to COVID-19 related issues. I can now say that I have professional experience as a full-stack developer and tools programmer. Despite my obvious accomplishments, I still carry a lot of self-doubt.</p>
                    <p>I think back to my time at Ubisoft, the circumstances under which I was hired, the empty promises made to break, and everything in between. I’ve started to wonder if the only reason I was hired was again <a href="https://us7.campaign-archive.com/?u=a5be9808956229f940c4db652&id=001b5e160b">for a PR story</a>. My contract was offered shortly before the announcement of the 2016 WIFT-T Ubisoft Toronto Producer Mentorship winner - and the article cited my joining the studio as a success of the program.</p>
                    <p>Did anyone at Ubisoft Toronto who had a say in my career trajectory actually believe I was capable of anything other than looking pretty and taking notes? Did Keith ever once even consider having me mentored properly into a technical role? Did my HR team lead laugh at me for wanting to do something more technical, not caring at all what happened to me so long as I didn’t make trouble?</p>
                    <p>I have thought about all of this for a long time, and I have wanted to speak up, but still I kept quiet.</p>
                    <p>That girl in the video, the one beaming about how much she loves games, is contagiously passionate. I look at her and I don’t believe it is me. I don’t feel that way anymore, about anything. I have not looked into engineering degrees. I have incrementally moved forward towards the goals I once had.</p>
                    <p>That girl in the video did not know what was waiting. That girl didn’t know that her future boss, Keith, thought science kits should be “pink for girls and blue for boys”. That girl didn’t know that she would never be allowed to grow under his supervision.</p>
                    <p>That girl didn’t know that the man she had a one-on-one interview with, the Creative Director Maxime Beland, had allegedly choked a woman at a staff party.</p>
                    <p>That girl didn’t know she would be told by a writer on Far Cry 5, in a recording booth, that he would never play Horizon Zero Dawn - even though she thought it was a great game - because you “can only play as a woman”. That girl didn’t know that she would be told by a partner studio’s director that he couldn’t follow what she was talking about in terms of voice over lines to be recorded because her “lipstick is so distracting”. <b>Twice.</b></p>
                    <p>That girl didn’t know that she would be groped by her future co-worker, when he took advantage of an opportunity to escort her home from an off-site party after she had too much to drink.</p>
                    <p>That girl did not know that she could develop a chronic drinking problem, that her depression would manifest into destroying her relationship with the guy she loved, that she would feel ugly and used and worthless. </p>
                    <p>That girl was never going to be safe, and many, many people at Ubisoft Toronto knew that, and still they led her in anyways.</p>
                    <p>So, this is my story. 19 months at Ubisoft, a seemingly short run, that aged me and soured me and broke me into something else. I am sharing all the details to fully exhibit a profile of a woman with a career in games. We all have stories, and though our experiences differ, our pain is the same.</p>
                    <p>My name is Chelsea O’Hara, my friends call me Chel. I was used as a poster-child, a success story, of a woman ‘breaking in’ to Ubisoft Toronto. Now I will collect that debt owed to me, and have revealed for anyone who wants to know what it cost me to watch them profit off of me, that girl in the video, the girl I used to be.</p>
                </div>
                </div>
                
                </div>
                
            </div>
        )
    }
};

export default PagePapersUbiTO