import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

class PagePapersTest extends Component {
    render() {
        return(
            <div className="Page-Container">
                <div className="Page">
                <h2 className="Page-Title">Papers</h2>
                <NavLink className="back-button" to="/Papers">← Back</NavLink>
                <div className="Paper-Container">
                <h3 className="Paper-Title">Hello World</h3>
                <div className="Paper-Details">Published 2020/06/30</div>
                <div className="Paper-Body">
                    <p>Yaaaas Queen</p>
                </div>
                </div>
                </div>
                </div>
        )
    }
}

export default PagePapersTest