import React, { Component } from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import logo from './logo.svg';
// import './App.css';

import './main.scss'

import Header from './Header/Header'
import Container from './Components/Container'

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
        <Header/>
        <Container/>
      </div>

      </Router>
      
    );
  }
}

export default App;
